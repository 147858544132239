import { styled } from '@material-ui/core/styles'

export const HeroWrapper = styled('div')(
  ({ theme, paddingtop, paddingbottom }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingTop: theme.spacing(paddingtop - 4),
    paddingBottom: theme.spacing(paddingbottom - 4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(paddingtop),
      paddingBottom: theme.spacing(paddingbottom)
    },
    overflow: 'hidden'
  })
)

export const HeroBackground = styled('img')(() => ({
  position: 'absolute',
  width: 600,
  right: 0,
  bottom: 0,
  transform: 'translateY(60%) rotate(-15deg)',
  opacity: 0.1,
  zIndex: 0
}))

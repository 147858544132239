import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { HeroWrapper, HeroBackground } from './ColorHeroSection.styles'

const WithContainer = ({ active, children }) => (
  <>{active ? <Container maxWidth='lg'>{children}</Container> : children}</>
)

const ColorHeroSection = ({
  title,
  subtitle,
  paddingtop,
  paddingbottom,
  children,
  align = 'center',
  withContainer = true,
  withBackground = false
}) => (
  <HeroWrapper paddingtop={paddingtop} paddingbottom={paddingbottom}>
    {withBackground && (
      <HeroBackground src='/static/images/Dropmock-AI-logo-white.svg' />
    )}
    <WithContainer active={withContainer}>
      <Typography variant='h1' align={align}>
        {title}
      </Typography>
      <Typography variant='h4' align={align}>
        {subtitle}
      </Typography>
      <Box>{children}</Box>
    </WithContainer>
  </HeroWrapper>
)

export default ColorHeroSection
